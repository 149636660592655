<template>
  <fragment> 
    <AnalysisAudi v-if="!role.isVolvo"/>
    <AnalysisAll v-else />
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import AnalysisAudi from './analysisAudi.vue';
import AnalysisAll from './analysisAll.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    AnalysisAudi,
    AnalysisAll,
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>

